
import store from "@/modules/adaptedStorage";
import { alertCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { defineComponent } from "vue";
import {
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonButton,
  IonIcon,
  modalController
} from "@ionic/vue";
import CyanPageWrapper from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";

export default defineComponent({
  name: "AgenteJornadaReportarIncidencia",
  components: {
    IonTextarea,
    IonButton,
    IonPage,
    IonIcon,
    IonSelect,
    IonSelectOption,
    CyanPageWrapper,
  },
  setup() {
    return {
      alertCircleOutline,closeCircleOutline
    }
  },
  props: {
    junta: {
      type: String,
      default: "0",
    },
    municipio: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tipoIncidencia: '1',
      comentarios: ''
    };
  },
  computed: {
    baseUrl(): string {
      const p = (this as any).$route.params;

      return "/agente/" + p.ambito + "/" + p.junta + "/";
    },
    seed(): any {
      return (this as any).$route.params.ambito || "SV";
    },
    tiposIncidencia(): any {
      if (
        store.state.bootstrap[this.junta] &&
        store.state.bootstrap[this.junta].tipos_incidencia
      )
        return store.state.bootstrap[this.junta].tipos_incidencia;
      else return { "0": "Error" };
    },
  },
  methods: {

    cerrarModal: modalController.dismiss,
    enviarIncidencia: async function() {
      await cyanRequest('agente/addIncidencia/' + this.junta, {
          needsAuth: true,
          isPost: true,
          isModal: true,
          params: {
            'tipo_incidencia': this.tipoIncidencia,
            'descripcion': this.comentarios
          }
      }).then((d) => {
        if (d.ok) {
          store.commit('storeIncidencia', d.incidencia);
          modalController.dismiss();
        } 
      });
    }
  },
});
