<template>
  <ion-page>
    <cyan-page-wrapper
      :title="'Reportar incidencia' + (junta ? ' (JRV ' + junta + ')' : '')"
      nav1="REPORTAR INCIDENCIA"
      :nav2="'JRV ' + junta"
      :ambito="municipio"
      :isModal="true"
      class="reportarIncidencia"
    >
      <div class="banner nivel1 hdr">DATOS DE LA INCIDENCIA A REPORTAR</div>

      <div class="banner nivel2">Tipo de incidencia</div>

      <ion-select
        cancelText="Cancelar"
        interface="action-sheet"
        v-model="tipoIncidencia"
      >
        <ion-select-option
          v-for="(caption, id) in tiposIncidencia"
          :key="id"
          :value="id"
        >
          {{ caption }}
        </ion-select-option>
      </ion-select>

      <div class="banner nivel2">Comentarios (opcional)</div>
      <ion-textarea v-model="comentarios"></ion-textarea>

      <ion-button expand="block" @click="enviarIncidencia">
        <!-- eslint-disable vue/no-deprecated-slot-attribute -->
        <ion-icon slot="start" :icon="alertCircleOutline"></ion-icon>
        <!-- eslint-enable vue/no-deprecated-slot-attribute -->

        ENVIAR INCIDENCIA</ion-button
      >
      <ion-button expand="block" @click="cerrarModal" color="secondary">
        <!-- eslint-disable vue/no-deprecated-slot-attribute -->
        <ion-icon slot="start" :icon="closeCircleOutline"></ion-icon>
        <!-- eslint-enable vue/no-deprecated-slot-attribute -->

        CANCELAR</ion-button
      >
    </cyan-page-wrapper>
  </ion-page>
</template>

<script lang="ts">
import store from "@/modules/adaptedStorage";
import { alertCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { defineComponent } from "vue";
import {
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonButton,
  IonIcon,
  modalController
} from "@ionic/vue";
import CyanPageWrapper from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";

export default defineComponent({
  name: "AgenteJornadaReportarIncidencia",
  components: {
    IonTextarea,
    IonButton,
    IonPage,
    IonIcon,
    IonSelect,
    IonSelectOption,
    CyanPageWrapper,
  },
  setup() {
    return {
      alertCircleOutline,closeCircleOutline
    }
  },
  props: {
    junta: {
      type: String,
      default: "0",
    },
    municipio: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tipoIncidencia: '1',
      comentarios: ''
    };
  },
  computed: {
    baseUrl(): string {
      const p = (this as any).$route.params;

      return "/agente/" + p.ambito + "/" + p.junta + "/";
    },
    seed(): any {
      return (this as any).$route.params.ambito || "SV";
    },
    tiposIncidencia(): any {
      if (
        store.state.bootstrap[this.junta] &&
        store.state.bootstrap[this.junta].tipos_incidencia
      )
        return store.state.bootstrap[this.junta].tipos_incidencia;
      else return { "0": "Error" };
    },
  },
  methods: {

    cerrarModal: modalController.dismiss,
    enviarIncidencia: async function() {
      await cyanRequest('agente/addIncidencia/' + this.junta, {
          needsAuth: true,
          isPost: true,
          isModal: true,
          params: {
            'tipo_incidencia': this.tipoIncidencia,
            'descripcion': this.comentarios
          }
      }).then((d) => {
        if (d.ok) {
          store.commit('storeIncidencia', d.incidencia);
          modalController.dismiss();
        } 
      });
    }
  },
});
</script>

<style scoped>
.hdr {
  margin-bottom: 1em;
}
</style>